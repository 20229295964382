import React from "react";
import "../styles/contact.scss";
import { SlLocationPin } from "react-icons/sl";
import { FiSmartphone } from "react-icons/fi";

import { AiOutlineMail } from "react-icons/ai";

function Contact() {
  return (
    <>
      <div className="parent-contact parent">
        <div className="cont-contact cont">
          <div className="contact-left bg-img-cover"></div>

          <div className="contact-right">
            <h2>Delivering Dependable Performance!</h2>
            <div className="contact2-cont-bottom-right">
              <div className="contacts-list">
                <div className="contact-item">
                  <div className="icons">
                    {" "}
                    <SlLocationPin />
                  </div>
                  <a href="#" target="_blank" className="item-details">
                    <h4>Address</h4>
                    <p>
                      43,Ramtara Housing Society,Behind Chanakya Puri, Ulkanagari,
                      Ch. Sambhaji Nagar, Maharashtra - 431005
                    </p>
                  </a>
                </div>
                <div className="contact-item">
                  <div className="icons">
                    {" "}
                    <FiSmartphone />{" "}
                  </div>
                  <a href="telto:7020512686" target="_blank" className="item-details">
                    <h4>Call</h4>
                    <p>(+91) 7020512686</p>
                  </a>
                </div>
                <div className="contact-item">
                  <div className="icons">
                    {" "}
                    <AiOutlineMail />{" "}
                  </div>
                  <a href="mailto:contact@tte.one" target="_black" className="item-details">
                    <h4>Email</h4>
                    <p>contact@tte.one</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
