import React from 'react'
import '../styles/header.scss'
import { Link } from 'react-router-dom'
import { MdLocalPhone } from "react-icons/md";

function Header() {
  return (
    <div>
      <div className="header-parent parent">
        <div className="header-cont cont">
            <div className="header-logo bg-img-cover"></div>
            <Link className='header-no'>
            <div className="icon"><MdLocalPhone /></div>
            <span className='no'>7020512686</span>
            </Link>
        </div>
      </div>
    </div>
  )
}

export default Header
