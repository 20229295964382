import './App.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './component/Header';
import V_M from './component/V_M';
import Contact from './component/Contact';
import Footer from './component/Footer';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Header />
      <V_M/>
      <Contact />
      {/* <Footer/> */}
      <Routes>
        <Route />
      </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
