import React from "react";
import "../styles/v&m.scss";
import { IoBulbOutline } from "react-icons/io5";
import { TbBrandFlightradar24 } from "react-icons/tb";
import { SlDiamond } from "react-icons/sl";

function V_M() {
  return (
    <>
      <div className="v-m-parent parent">
        <div className="v-m-cont cont">
          <div className="box1 box">
            <span className="v-icon">
              <IoBulbOutline/>
            </span>
            <h3>Vission</h3>
            <p>
            Triumph Enterprise leads through innovation, transforming industries, empowering global success with cutting-edge solutions, and unwavering commitment to excellence in all endeavors.
            </p>
          </div>
          <div className="box2 box">
            <span className="v-icon">
            <TbBrandFlightradar24 />
            </span>
            <h3>Mission</h3>
            <p>
            Triumph Enterprise is dedicated to delivering exceptional value to clients through innovative solutions, operational excellence, and a commitment to exceeding expectations, driving sustainable growth and global impact.
            </p>
          </div>
          <div className="box3 box">
            <span className="v-icon">
            <SlDiamond />
            </span>
            <h3>Values</h3>
            <p>
            Triumph Enterprise values integrity, innovation, excellence, collaboration, sustainability, and societal impact, prioritizing client success, employee well-being, and ethical principles for continuous improvement
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default V_M;
